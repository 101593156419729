<template>
  <div>
    <div class="mb-2 mt-3">
      <label for="formFile" class="form-label">{{ label }}</label>
      <input
        class="form-control"
        type="file"
        id="formFile"
        @change="upload"
        ref="uploader"
      />
    </div>

    <div class="progress" v-if="fileSelected">
      <div
        :class="'progress-bar progress-bar-striped ' + progressClass"
        role="progressbar"
        :style="'width: ' + progressValue + '%'"
        :aria-valuenow="progressValue"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>

    <div class="uploaded-image" v-if="photo">
      <img :src="photo" />
    </div>

    <div class="uploaded-video" v-if="video">
      <video :src="video"></video>
    </div>
  </div>
</template>

<script>
import { config } from "../helpers";

export default {
  props: ["label", "photo", "video"],

  data() {
    return {
      selectedFile: null,
      fileName: null,

      fileSelected: false,
      progressClass: "",
      progressValue: 20,
    };
  },

  mounted() {},

  methods: {
    upload() {
      this.selectedFile = this.$refs.uploader.files[0];

      this.photo = null;
      this.video = null;
      this.fileSelected = true;
      this.progressClass = "";
      this.progressValue = 20;

      const formData = new FormData();
      formData.append("file", this.selectedFile);
      formData.append("type", this.selectedFile.type.split('/')[0]);

      const headers = { "Content-Type": "multipart/form-data" };

      axios
        .post(config("BACKEND_ROOT") + "/admin/uploads", formData, { headers })
        .then((res) => {
          this.fileName = res.data.data.file_name;
          this.$emit("done", this.fileName);

          if (this.selectedFile.type.startsWith("video/")) {
            this.captureFirstFrame(this.selectedFile)
              .then((imageData) => {
                const imageFormData = new FormData();
                imageFormData.append("file", this.dataURLtoBlob(imageData));
                imageFormData.append("type", "image");

                axios
                  .post(config("BACKEND_ROOT") + "/admin/uploads", imageFormData, { headers })
                  .then((imageRes) => {              
                    this.$emit("coverPhoto", imageRes.data.data.file_name);
                  })
                  .catch((err) => console.error("Error uploading cover photo", err));
              })
              .catch((err) => console.error("Error capturing video frame", err));
          }

          this.progressClass = "bg-success";
          this.progressValue = 100;
        })
        .catch((error) => {
          this.progressClass = "bg-danger";
          this.progressValue = 100;

          alert(error.response.data.errors.file[0]);
        });
    },
    captureFirstFrame(file) {
      return new Promise((resolve, reject) => {
        const video = document.createElement("video");
        const reader = new FileReader();

        reader.onload = () => {
          video.src = reader.result;
          video.load();

          video.onloadeddata = () => {
            video.currentTime = 0;

            video.onseeked = () => {
              const canvas = document.createElement("canvas");
              const context = canvas.getContext("2d");

              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;

              context.drawImage(video, 0, 0, canvas.width, canvas.height);

              const imageData = canvas.toDataURL("image/jpeg");

              resolve(imageData);
            };
          };
        };

        reader.onerror = (err) => reject(err);
        reader.readAsDataURL(file);
      });
    },

    dataURLtoBlob(dataURL) {
      const byteString = atob(dataURL.split(',')[1]);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uint8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
      }

      const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
      return new Blob([uint8Array], { type: mimeString });
    },
  },
};
</script>

<style scoped>
.uploaded-image {
  width: 150px;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 10px;
}
.uploaded-image img {
  width: 100%;
}

.uploaded-video {
  width: 150px;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 10px;
}

.uploaded-video video {
  width: 100%;
}
</style>